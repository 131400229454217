import { host, IParams } from './index'
import { SettingAdvanceModel } from '@/models'
import { Axios } from '@/configs'

export interface SettingAdvanceResponse {
    readonly settingAdvances: SettingAdvanceModel[]
}

export class SettingAdvanceService {
    public static getSettingAdvanceOther = async (): Promise<SettingAdvanceResponse> => {
        const url = host + `/SettingAdvances?group=OTHER`
        const data = await Axios.get<SettingAdvanceResponse, any>(url)
        return data.data
    }
}