import { host, IParams } from './index'
import { Axios } from '@/configs'
import { UserModel } from '@/models'


export class UserService {
    public static getUserByToken = async (): Promise<UserModel> => {
        const url = host + '/Users/current'
        const res = await Axios.get(url)
        return res.data
    }
}