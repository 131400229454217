import { FormLoginValues } from '@/forms'
import { Axios } from '@/configs'
import { hostAuth } from './index'


// const hostAuth = process.env.NEXT_PUBLIC_AUTH

export interface ITokenResponse {
    token: string
    message?: string
}

class AuthService {
    public static postLogin = async (body: FormLoginValues): Promise<ITokenResponse> => {
        const url = hostAuth + '/api/create-managers-token'
        const res = Axios.post<any, ITokenResponse>(url, JSON.stringify(body))
        return res;
    }
}

export { AuthService }