import { InvoiceModel, RaitingSettingModel, InvoiceStaffRatingModel, CustomerModel, UserModel } from '@/models'
import { ActionType, ActionName, setSalonRatingSetting } from './actions'
import { SalonRatingSettingModel } from '@/models/salonRatingSettingModel'


export interface IInitialState {
    invoice?: InvoiceModel
    raitingSetting?: RaitingSettingModel
    invoiceStaffRaiting?: InvoiceStaffRatingModel[]
    customer?: CustomerModel
    user?: UserModel,
    salonId?: number,
    salonBranchId?: string
    salonRatingSetting?: SalonRatingSettingModel[]
}

export const initialState: IInitialState = {
    invoice: {},
    raitingSetting: {},
    invoiceStaffRaiting: [],
    customer: {},
    salonRatingSetting: [],
}

export type reducerType = (state: IInitialState, action: ActionType) => IInitialState

export const reducer = (state: IInitialState, action: ActionType) => {
    const { type, payload } = action;
    const currenInvoiceStaffRaiting = state.invoiceStaffRaiting?.find(item => item.staffId == payload.staffId)

    switch (type) {
        case ActionName.SET_INVOICE:
            return {
                ...state,
                invoice: payload
            };
        case ActionName.SET_RAITING_SETTING:
            return {
                ...state,
                raitingSetting: payload
            };
        case ActionName.SET_INVOICE_STAFF_RAITING:
            return {
                ...state,
                invoiceStaffRaiting: payload
            }
        case ActionName.SET_ICON_RAITNG:
            Object.assign(currenInvoiceStaffRaiting!, { ratingItemId: payload.ratingItemId })
            return {
                ...state
            }
        case ActionName.SET_FEEDBACK_RAITING:
            const feedbackRaitingId = payload.default_feedback_id
            if (!currenInvoiceStaffRaiting?.invoiceStaffRatingDefaultFeedback?.find(item => item.defaultFeedbackId == feedbackRaitingId)) {
                Object.assign(currenInvoiceStaffRaiting!,
                    { invoiceStaffRatingDefaultFeedback: [...(currenInvoiceStaffRaiting?.invoiceStaffRatingDefaultFeedback)!, { defaultFeedbackId: feedbackRaitingId }] }
                )
            }

            return {
                ...state
            }
        case ActionName.UN_SET_FEEDBACK_RAITING:
            if (currenInvoiceStaffRaiting?.invoiceStaffRatingDefaultFeedback?.find(item => item.defaultFeedbackId == payload.default_feedback_id)) {
                const newFeedbackIds = currenInvoiceStaffRaiting?.invoiceStaffRatingDefaultFeedback?.filter(item => item.defaultFeedbackId != payload.default_feedback_id)
                Object.assign(currenInvoiceStaffRaiting!, { invoiceStaffRatingDefaultFeedback: newFeedbackIds })
            }

            return {
                ...state
            }
        case ActionName.SET_OTHER_FEEDBACK_RAITING:
            const otherFeedBackMessage = payload.otherFeedback
            Object.assign(currenInvoiceStaffRaiting!, { otherFeedback: otherFeedBackMessage })

            return {
                ...state
            }

        case ActionName.SET_TIP_FOR_STAFF:
            const tip = payload.tip
            Object.assign(currenInvoiceStaffRaiting!, { tip: tip, isKeyBoardTip: payload.isKeyBoard })
            return {
                ...state
            }

        case ActionName.SET_CUSTOMER_FOR_INVOICE:
            return {
                ...state,
                customer: payload
            }
        
        case ActionName.SET_USER:
            return {
                ...state,
                user: payload
            }

        case ActionName.SET_SALON_ID:
            return {
                ...state,
                salonId: payload
            }
    
        case ActionName.SET_SALON_BRANCH_ID:
            return {
                ...state,
                salonBranchId: payload
            }
            case ActionName.SET_SALON_RAITING_SETTING:
                return {
                    ...state,
                    salonRatingSetting: payload
                }

        default:
            return state;
    }
}

