import { host, IParams } from './index'
import { Axios } from '@/configs'
import { SalonBranchModel } from '@/models'



export interface SalonBranchResponse {
    data: SalonBranchModel[]
}

export class SalonBranchService {

    public static getSalonBranch = async (params?: IParams): Promise<SalonBranchResponse> => {
        const url = host + '/SalonBranchs'
        const data = await Axios.get<SalonBranchResponse, any>(url, { params })
        return data
    }

    public static selectSalonBranch = async (salonBranchId: number, params?: IParams) => {
        const url = host + `/Users/select-staff-rating-branch/${salonBranchId}`
        const data = await Axios.put<SalonBranchResponse, any>(url, { params })
        return data
    }
}