import React, { ReactElement } from "react";
import styled from "styled-components";
import { Button, ButtonProps } from "antd";

const getWidthButton = (size: string | undefined) => {
  switch (size) {
    case "large":
      return "796px";
    case "middle":
      return "400px";
    case "small":
      return "347px";
  }
};

const PrimaryButtonStyled = styled(Button)<ButtonProps>`
  width: ${(props) => getWidthButton(props.size)};
  height: 78px;
  border-radius: 18px;
  background-color: ${(props) =>
    props.disabled ? "#DEE2EA" : "#0068FF"} !important;
  border-color: none !important;

  h3 {
    color: ${(props) => (props.disabled ? "#808B9F" : "#FFFFFF")} !important;
  }
`;

export function PrimaryButton(props: ButtonProps) {
  return <PrimaryButtonStyled {...props} className="text-3xl font-bold" />;
}
