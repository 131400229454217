import React, { ReactElement } from "react";
import Head from "next/head";
import styled from "styled-components";
import { Timer } from "@/components";
import Image from "next/image";
import { getSalonLocalStorage } from "@/utils";

export interface LayoutProps {
  title?: string;
  children?: ReactElement | JSX.Element | undefined;
}

const StyledLayoutWrapper = styled.div``;

export function Layout(props: LayoutProps) {
  const { title = "Đánh giá", children } = props;
  const salon = getSalonLocalStorage();

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta name="description" content="Generated by create next app" />
        <link rel="shortcut icon" href="/statics/Logo-easysalon.png" />
      </Head>
      <StyledLayoutWrapper>
        <header className="w-full p-[36px] flex justify-between items-center">
          <Timer />
          <div className="flex items-center">
            <h2 className="text-4xl mx-[26px] text-primary font-bold mb-0">
              {salon?.name}
            </h2>
            {salon?.photo?.url && (
              <Image
                src={salon?.photo?.url}
                alt="salon-img"
                width={90}
                height={90}
              />
            )}
          </div>
        </header>
        <div>{children}</div>
      </StyledLayoutWrapper>
    </>
  );
}
