/**
 * fill array of numbers from 1 to 9
 */
const numbers = Array.from({ length: 9 }, (_, i) => (i + 1).toString());

/**
 * Keyboard possible characters
 */
const keyboardCharacters = {
  '0': '0',
  '1': '1',
  '2': '2',
  '3': '3',
  '4': '4',
  '5': '5',
  '6': '6',
  '7': '7',
  '8': '8',
  '9': '9',
  Backspace: 'Backspace',
};

/**
 * Default keyboard transition time
 */

const DEFAULT_CLOSE_ANIMATION_TIME = 300;

const BACKSPACE_ICON = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAgCAYAAABzenr0AAAABHNCSVQICAgIfAhkiAAAAAlwSFlzAAAA7AAAAOwBeShxvQAAABl0RVh0U29mdHdhcmUAd3d3Lmlua3NjYXBlLm9yZ5vuPBoAAAFFSURBVFiF7da9LgRRGMbx325IhAtAS1BKXACJREFcgUayDaVVKFBwDZZot3ELdOgVEir0ElEobELCrmJmY032Y2Z2ZZv5J5NMznmf8z7n+5CRkdFncgliV7GMgZS5rnGWUmsdVdS6+KqYjjYcpzcTOMI3CnhO0YEdLGEMD0mEecHQ1XCQInGdctjGfFLhfii8wWCkLofhFrqRXhiYwycqmGlSf4o3LETKi/jCWjcGhnAfijZaxBTC+vcGE9sNZbPdGDgOBefab9WtMK6Ck4b/xUhcIgOTgi3zgtEY8XUTNXw0Sd7WQD6Oow4kOcxiUxI4vuiQoOh32Et6NAUEi/AuFG22iPnXRUift2GdPX08iAgW6VUoPkwqjmOg02VUFcz1LXbxJN1lNJ5C84deXcdT0YbjPi7KeMVKAk2USzym1GZkZPwfPzWefqf6kHHsAAAAAElFTkSuQmCC';

export { numbers, keyboardCharacters, DEFAULT_CLOSE_ANIMATION_TIME, BACKSPACE_ICON };
