import { host } from "./index"
import { Axios } from '@/configs'
import { CustomerModel } from '@/models'

export class CustomerService {

    public static addCustomer = async (customerBody: CustomerModel) => {
        const url = host + '/Customers'
        const res = await Axios.post(url, customerBody)
        return res.data
    }

    public static getCustomerByPhoneNumber = async (phoneNumber: string): Promise<CustomerModel> => {
        const url = host + `/Customers/get-by-mobile/${phoneNumber}`
        const res = await Axios.get(url)
        return res.data
    }

}