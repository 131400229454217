import { host, IParams } from './index'
import { Axios } from '@/configs'
import { RaitingSettingModel, InvoiceStaffRatingModel } from '@/models'

export interface PaymentQrResponse {
    readonly paymentUrl: string
}

export class RaitingService {
    public static getRaitingSettingBySalonId = async (salonId: string, params?: IParams): Promise<RaitingSettingModel> => {
        const url = host + `/StaffRatingSettings/get-by-salon?salonId=${salonId}`
        const data = await Axios.get<RaitingSettingModel, any>(url, { params })
        return data.data
    }
    public static GetPaymentQrUrl = async (salonBranchId: string, amount: number, customerName: string): Promise<PaymentQrResponse> => {
        const url = host + `/InvoiceStaffRatings/get-payment-qr/${amount}?salonBranchId=${salonBranchId}&customerName=${customerName}`
        const data = await Axios.get<PaymentQrResponse, any>(url)
        return data.data
    }
    public static postCheckoutRaiting = async (body: InvoiceStaffRatingModel[], invoiceId: string) => {
        const url = host + `/InvoiceStaffRatings/add-invoice-staff-ratings/${invoiceId}`
        const data = await Axios.post(url, body)
        return data.data
    }

}