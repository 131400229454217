import React, { useEffect, useState } from "react";
import moment from "moment";

export interface ITimerState {
  date?: string;
  time?: string;
}

export function Timer() {
  const [date, setDate] = useState<ITimerState>({});

  useEffect(() => {
    const handleChangeDate = () => {
      const newDate = moment().format("DD/MM/YYYY");
      const newTimer = moment().format("HH:mm:ss");
      setDate({
        date: newDate,
        time: newTimer,
      });
    };
    const timeoutId = setTimeout(handleChangeDate, 1000);

    return () => {
      clearTimeout(timeoutId);
    };
  }, [date]);

  return (
    <div className="flex flex-col items-center">
      <h2 className="mb-[6px] font-bold">{date.time}</h2>
      <h4>{date.date}</h4>
    </div>
  );
}
