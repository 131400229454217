export * from './invoiceService'
export * from './salonBranchService'
export * from './authService'
export * from './salonService'
export * from './raitingService'
export * from './CommisionArrangeService'
export * from './customerService'
export * from './userService'
export * from './settingAdvance'

export const hostAuth = process.env.NEXT_PUBLIC_AUTH
export const host = process.env.NEXT_PUBLIC_HOST

export interface IParams {
    totalItem?: number
    totalPage?: number,
    rowPerPage?: number,
    currentPage?: number,
    query?: {}
}