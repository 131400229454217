import "antd/dist/antd.css";
import "../styles/datepicker.css";
import "../styles/numericKeyboard.css";
import "../styles/globals.css";

import type { AppProps } from "next/app";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { CheckAuth } from "@/HOCS";
import { StoreProvider } from "@/store";
import Head from "next/head";
import { ReactNode } from "react";
import React from 'react';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  }
});

// to disable SSR in NextJS
function SafeHydrate({ children }: { children: ReactNode; }) {
  return (
    <div suppressHydrationWarning>
      {typeof window === "undefined" ? null : children}
    </div>
  );
}

function MyApp({ Component, pageProps }: AppProps) {
  const [showChild, setShowChild] = React.useState(false);
  React.useEffect(() => {
    setShowChild(true);
  }, []);

  if (!showChild) {
    return null;
  }

  return (
    <>
    <Head>
      <meta name="apple-mobile-web-app-capable" content="yes" />
      <meta name="apple-mobile-web-app-status-bar-style" content="default" />
      <meta
        name="apple-mobile-web-app-title"
        content="EasySalon: Đánh giá thợ"
      />
      <meta name="theme-color" content="#FFFFFF" />
      <link rel="manifest" href="/manifest.json" />
      <link
        href="/statics/icon-196.png"
        rel="icon"
        type="image/png"
        sizes="196x196"
      />
      <link rel="apple-touch-icon" href="/statics/icon-196.png"></link>
    </Head>
    <SafeHydrate>
      <QueryClientProvider client={queryClient}>
        <StoreProvider>
          <CheckAuth>
            <Component {...pageProps} />
          </CheckAuth>
        </StoreProvider>
      </QueryClientProvider>
    </SafeHydrate>
    </>
  );
}

export default MyApp;
