import React, { useEffect, useState, ReactElement, useCallback } from "react";
import { useRouter } from "next/router";
import { getToken } from "@/utils";
import { Loading } from "@/components";
import { setUser, useStore } from "@/store";
import { UserService } from "@/services";
import { useQuery } from "@tanstack/react-query";

export interface CheckAuthProps {
  children: JSX.Element;
}

export function CheckAuth(props: CheckAuthProps) {
  const router = useRouter();
  const token = getToken();
  const [state, dispatch] = useStore();
  const { asPath } = router;

  const { data: user, isFetching } = useQuery(
    ["user"],
    () => UserService.getUserByToken(),
    { enabled: !!token }
  );

  const tryToLogin = useCallback(() => {
    if (asPath === "/login") {
      return;
    }

    router.push("/login");
  }, [asPath, router]);

  useEffect(() => {
    dispatch(setUser(user));
  }, [dispatch, user]);

  useEffect(() => {
    if (!token) {
      return tryToLogin();
    }

    if (isFetching) {
      return;
    }

    if (!user) {
      return tryToLogin();
    }
  }, [asPath, isFetching, token, tryToLogin, user]);

  if (isFetching) {
    return <Loading />;
  }

  return props.children ;
}
