import { host, IParams } from './index'
import { CommissionArrangeModel } from '@/models'
import { Axios } from '@/configs'

export interface CommisionArrangeResponse {
    id: string,
    invoiceStaffArrangements: CommissionArrangeModel[]
}

export class CommisionArrangeService {
    public static getComissionArrangeByInvoiceId = async (invoiceId: string, params?: IParams): Promise<CommisionArrangeResponse> => {
        const url = host + `/CommissionArrangements?invoiceId=${invoiceId}&orderType=DESC`
        const data = await Axios.get<CommisionArrangeResponse, any>(url, { params })
        return data.data
    }
}