import { host, IParams } from './index'
import { Axios } from '@/configs'
import { SalonRatingSettingModel } from '@/models/salonRatingSettingModel'

export interface SalonRatingSettingResponse {
    data: SalonRatingSettingModel[]
}

export class SalonRatingSettingService {
    public static getSalonRatingSettingBySalonId = async (type?: string, params?: IParams): Promise<SalonRatingSettingResponse> => {
        const typeUrl = type ? `type=${type}` : ''
        const url = host + `/SalonRatingSettings?${typeUrl}`
        const data = await Axios.get<SalonRatingSettingResponse, any>(url, { params })
        return data
    }
}