

export function currencyStr(number?: number) {
  if (number) {
    return number
      ?.toFixed(1)
      .replace(/\d(?=(\d{3})+\.)/g, "$&.")
      .slice(0, -2);
  }

  return 0;
}

// export function setCok