import React from "react";
import { Space, Table, Tag } from "antd";
import type { ColumnsType } from "antd/es/table";
import { InvoiceDetailModel } from "@/models";
import { currencyStr } from "@/utils";

const columns: ColumnsType<InvoiceDetailModel> = [
  {
    title: <span className="text-title-table">SP/DV/Gói đã sử dụng</span>,
    dataIndex: "objectName",
    key: "objectName",
    render: (objectName, _, index) => (
      <span className="text-column-table">
        {index + 1}. {objectName}
      </span>
    ),
  },
  {
    title: (
      <span className="text-title-table w-full block text-center">
        Số lượng
      </span>
    ),
    dataIndex: "quantity",
    key: "quantity",
    render: (quantity) => (
      <span className="text-column-table w-full block text-center">
        {quantity}
      </span>
    ),
  },
  {
    title: (
      <span className="text-title-table w-full block text-right">Đơn giá</span>
    ),
    dataIndex: "objectPrice",
    key: "objectPrice",
    render: (objectPrice) => (
      <span className="text-column-table w-full block text-right">
        {currencyStr(objectPrice)} đ
      </span>
    ),
  },
  {
    title: (
      <span className="text-title-table w-full block text-right">
        Thành tiền
      </span>
    ),
    key: "totalExcludeDiscount",
    dataIndex: "totalExcludeDiscount",
    render: (totalExcludeDiscount, invoiceDetail) => (
      <span className="flex flex-col items-end">
        <span className="text-column-table">
          {currencyStr(totalExcludeDiscount)} đ
        </span>
        {invoiceDetail.discountValue ? (
          <span className="text-[12px] text-[#808B9F]">{`Giảm: ${currencyStr(
            invoiceDetail.totalIncludeDiscount! -
              invoiceDetail.totalExcludeDiscount!
          )}đ`}</span>
        ) : (
          ""
        )}
      </span>
    ),
  },
];

export interface InvoiceDetailTableProps {
  invoiceDetails?: InvoiceDetailModel[];
}

export function InvoiceDetailTable(props: InvoiceDetailTableProps) {
  const { invoiceDetails } = props;

  return (
    <Table
      columns={columns}
      dataSource={invoiceDetails}
      pagination={false}
      bordered={true}
      rowKey="id"
    />
  );
}
