import { host, IParams } from './index'
import { InvoiceModel } from '@/models'
import { Axios } from '@/configs'

export interface invoiceResponse {
    data: InvoiceModel
}

export class InvoiceService {
    public static getInvoiceById = async (invoiceId: string, params?: IParams): Promise<invoiceResponse> => {
        const url = host + `/invoices/${invoiceId}`
        const data = await Axios.get<InvoiceModel, any>(url, { params })
        return data
    }

    public static updateCustomerForInvoice = async (invoiceId: string, customerId: string) => {
        const url = host + `/Invoices/add-customer-from-staff-rating/${invoiceId}/${customerId}`
        const res = await Axios.put(url, {})
        return res
    }
}