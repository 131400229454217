import axios from 'axios';
import { getToken } from '@/utils'

const Axios = axios.create({
    baseURL: process.env.NEXT_PUBLIC_HOST,
    headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json'
    },
});

Axios.interceptors.request.use((config) => {
    if (config && config.headers) {
        const token = getToken();
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
    }
    return config;
});

Axios.interceptors.response.use((response) => {
    if (response && response.data) {
        return response.data;
    }
    return response;
});

export { Axios };
